import React, { useState, useContext, useEffect, CSSProperties } from 'react';
import { motion } from 'framer-motion';
import gsap from 'gsap';

import WindowContext from '../../contexts/WindowContext';
import NavButton from '../navigation/navButton/NavButton';
import Container from '../../hoc/container/Container.js';
import ScrollDown from '../navigation/scrollDown/ScrollDown';
import HEADER_DATA from '../../resources/headerData.js';
import LoadingContext from '../../contexts/LoadingContext';

import video from '../../assets/videos/home.mp4';
import style from './Header.module.scss';

type HeaderProps = {
	dataKey: string;
	containerStyle?: CSSProperties;
	showBackgroundOnly?: boolean;
};

const VIDEO_ASPECT_RATIO = 9 / 16; // 16:9 aspect ratio

function calculateVideoDimensions(width: number, height: number) {
	const windowAspect = height / width;
	let videoDimensions: [number, number] = [0, 0];
	if (windowAspect > VIDEO_ASPECT_RATIO) {
		// > 16:9 (most screens)
		const videoWidth = Math.ceil((windowAspect / VIDEO_ASPECT_RATIO) * width);
		const videoHeight = height;
		videoDimensions = [videoWidth, videoHeight];
	} else {
		// < 16:9 Ultra widescreen
		const videoWidth = width;
		const videoHeight = Math.ceil((VIDEO_ASPECT_RATIO / windowAspect) * height);
		videoDimensions = [videoWidth, videoHeight];
	}
	return videoDimensions;
}

const Header: React.FC<HeaderProps> = ({ dataKey, containerStyle, showBackgroundOnly = false }) => {
	const hasLoaded = useContext(LoadingContext);
	const windowDimensions = useContext(WindowContext);
	const [videoDimensions, setVideoDimensions] = useState<[number, number]>(
		calculateVideoDimensions(windowDimensions.width, windowDimensions.height)
	);
	const data = HEADER_DATA[dataKey];

	useEffect(() => {
		const { width, height } = windowDimensions;
		setVideoDimensions(calculateVideoDimensions(width, height));
	}, [windowDimensions]);

	useEffect(() => {
		const videoParallax = gsap.to('#header-video', {
			opacity: 0,
			ease: 'none',
			scrollTrigger: { trigger: '#header', scrub: true, start: 'top top', end: 'bottom top' },
		});
		return () => {
			videoParallax.kill();
		};
	}, []);

	return (
		<header className={style.container} style={containerStyle} id="header">
			<div className={`${style.video} ${hasLoaded && style.videoLoaded}`} id="header-video">
				<video src={video} autoPlay muted loop width={videoDimensions[0]} height={videoDimensions[1]} />
			</div>

			{/* <BackgroundImage show={showImage} images={data.images} /> */}

			<div className={style.overlay} />

			{/* TEMP WORKAROUND - NEEDS UPDATING */}
			{!showBackgroundOnly && hasLoaded && (
				<>
					<Container containerClass={style.headings}>
						<motion.h1 initial={{ y: 32, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.5 }}>
							{data.text.heading}
						</motion.h1>
						<motion.h3 initial={{ y: 32, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.8 }}>
							{data.text.subheading}
						</motion.h3>
						{dataKey === '/' && (
							<motion.div
								initial={{ y: 24, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ delay: 1.1 }}
							>
								<NavButton id="contact" to="/contact" title="Book a Consultation" isMobile={false} />
							</motion.div>
						)}
					</Container>
					<div className={style.scrollDown}>
						<ScrollDown />
					</div>
				</>
			)}
		</header>
	);
};

export default Header;
