import homeHR from '../assets/header/home-static.jpg';
import homeLR from '../assets/header/home-staticLR.jpg';
import aboutHR from '../assets/header/about-static.jpg';
import aboutLR from '../assets/header/about-staticLR.jpg';
import charteringHR from '../assets/header/chartering-static.jpg';
import charteringLR from '../assets/header/chartering-staticLR.jpg';
import crewHR from '../assets/header/crew-static.jpg';
import crewLR from '../assets/header/crew-staticLR.jpg';
import purchasingHR from '../assets/header/purchasing-static.jpg';
import purchasingLR from '../assets/header/purchasing-staticLR.jpg';
import contactHR from '../assets/header/contact-static.jpg';
import contactLR from '../assets/header/contact-staticLR.jpg';

import links from './links';

// VIDEO URLS

// 1.1
// 312264097

// 1.2
// 312264879

// 2
// https://player.vimeo.com/video/312265882

// 3
// 312267028

// 4
// 312267324

// 5
// 312267292

// 6
// 312267597

// 7
// 312267720

const HEADER_DATA = {
	[links.home.link]: {
		images: {
			hr: homeHR,
			lr: homeLR,
		},
		// Video 2
		videoURL: 'https://player.vimeo.com/video/312265882?background=1',
		text: {
			// A Superior Holiday With Your Loved Ones
			heading: 'Superior Chartering With Your Loved Ones',
			subheading: "We'll help you organise the perfect yachting experience. Stress-free is our guarantee.",
		},
	},

	[links.about.link]: {
		images: {
			hr: aboutHR,
			lr: aboutLR,
		},
		videoURL: 'https://player.vimeo.com/video/312267028?background=1',

		text: {
			heading: 'Clarity and Certainty All The Way',
			subheading:
				'We pride ourselves on the finer details. Our mission is to craft unforgettable experiences for you and your loved ones.',
		},
	},

	[links.destinations.link]: {
		images: {
			hr: crewHR,
			lr: crewLR,
		},
		videoURL: 'https://player.vimeo.com/video/312267292?background=1',

		text: {
			heading: 'Destined For You. An Unforgettable Experience.',
			subheading: 'Destinations sub heading',
		},
	},

	[links.contact.link]: {
		images: {
			hr: contactHR,
			lr: contactLR,
		},
		videoURL: 'https://player.vimeo.com/video/312267597?background=1',

		text: {
			heading: '50% Off Your Broker Fees with Superior Yachting',
			subheading: "Complete the form below and we'll answer your questions today.",
		},
	},

	[links.jobs.link]: {
		images: {
			hr: crewHR,
			lr: crewLR,
		},
		videoURL: 'https://player.vimeo.com/video/312267292?background=1',

		text: {
			heading: 'Amazing Opportunities Await Onboard',
			subheading: 'We can help you find the perfect role onboard one of our incredible vessels.',
		},
	},
};

export default HEADER_DATA;

// [links.watersports.link]: {
// 	images: {
// 		hr: crewHR,
// 		lr: crewLR,
// 	},
// 	videoURL: 'https://player.vimeo.com/video/312267292?background=1',

// 	text: {
// 		heading: 'THE ULTIMATE IN WATERSPORTS',
// 		subheading: 'A new level of excitement with the latest in watersport technology',
// 	},
// },

// [links.chartering.link]: {
// 	images: {
// 		hr: charteringHR,
// 		lr: charteringLR,
// 	},

// 	videoURL: 'https://player.vimeo.com/video/312264879?background=1',

// 	text: {
// 		heading: 'STRESS FREE CHARTERING FROM START TO FINISH',
// 		subheading: 'We source the ideal yacht for your specific requirements',
// 	},
// },

// [links.purchasing.link]: {
// 	images: {
// 		hr: purchasingHR,
// 		lr: purchasingLR,
// 	},

// 	videoURL: 'https://player.vimeo.com/video/312264097?background=1',

// 	text: {
// 		heading: 'YACHT PURCHASING',
// 		subheading: 'Please enquire',
// 	},
// },
