import React from 'react'
// import style from './Spinner.module.css'
// TODO: fix this.
const spinner = props => {
  return null
  // return (
  //   <div className={style.foldingCube}>
  //     <div className={style.cube} />
  //     <div className={style.cube2} />
  //     <div className={style.cube4} />
  //     <div className={style.cube3} />
  //   </div>
  // )
}

export default spinner
