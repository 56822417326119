const links = {
  home: {
    link: '/',
    title: 'Welcome',
    description: 'Welcome to Superior Yachting'
  },

  chartering: {
    link: '/browse',
    title: 'Charter',
    description: 'We offer ongoing support from enquiry until charter completion'
  },

  purchasing: {
    link: '/yacht-purchasing',
    title: 'Purchase',
    description:
      'With our extensive knowledge of the industry we can find the ideal yacht to suit your needs'
  },

  destinations: {
    link: '/yacht-destinations',
    title: 'Destinations',
    description: ''
  },

  watersports: {
    link: '/yacht-watersports',
    title: 'Watersports',
    description: ''
  },

  jobs: {
    link: '/yacht-jobs',
    title: 'Jobs',
    description: 'Latest job opportunities for interior and exterior crew members.'
  },

  about: {
    link: '/about-us',
    title: 'About',
    description: 'Find out why Superior Yachting should be your yacht broker of choice'
  },

  contact: {
    link: '/contact',
    title: 'Contact',
    description: ''
  }
}

export default links
