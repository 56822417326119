import React, { useEffect } from 'react';

import Header from '../components/header/Header';
import Container from '../hoc/container/Container';
import Button from '../components/ui/button/Button';

import links from '../resources/links';

import style from './jobs.module.css';

const JobsPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header dataKey={links.jobs.link} />
			<section className={style.jobs}>
				<Container>
					{JOBS.map((job, i) => {
						return (
							<div className={style.job} key={i}>
								<h3>{job.heading}</h3>
								<p>{job.body}</p>
								<p style={{ opacity: 0.6 }}>Please send an up to date CV with short cover letter</p>
								<a href="mailto:info@superioryachting.com">
									<Button label="Apply Now" isSecondary isOutlined onPress={() => {}} />
								</a>
							</div>
						);
					})}
				</Container>
			</section>
		</>
	);
};

export default JobsPage;

const JOBS = [
	{
		heading: <>100M+ Private/Charter Yacht looking for experienced Deckhand</>,
		body: (
			<>
				Must hold YM offshore and a minimum of 2 years’ experience on a large yacht. Salary Dependant on experience.
				Longevity and charter experience a big plus.
			</>
		),
	},
	{
		heading: <>100M+ Private/Charter Yacht looking for experienced 2nd Officer</>,
		body: (
			<>
				Must hold OOW Unlimited and have a minimum of 2 years large yacht experience. Large tender driving skills and
				excellent product knowledge essential as you will be responsible for the running of the deck crew. Excellent
				salary on offer. Rotation 2 months on 2 months off.
			</>
		),
	},
	{
		heading: <>55M Charter Yacht looking for 2nd Stewardess</>,
		body: (
			<>
				Must have minimum of 3 years’ experience in the industry on similar size yachts. Must be confident in all aspects
				of interior roles and be capable of stepping in for the Chief Stew when needed. Charter experience essential.
				Priority will be given to candidates with B1/B2 in hand. Salary dependant on experience.
			</>
		),
	},
];
