import React from 'react';
import Spinner from '../spinner/Spinner';
import style from './Button.module.css';

type ButtonProps = {
	label: string;
	onPress?: any;
	id?: string;
	icon?: string;
	isLoading: boolean;
	isDisabled?: boolean;
	isFullWidth?: boolean;
	isTertiary?: boolean;
	isSecondary?: boolean;
	isOutlined?: boolean;
	isContained?: boolean;
	classes?: string[];
	// TODO: Change all refereneces
};

const Button: React.FC<ButtonProps> = (props) => {
	const {
		id,
		label,
		icon,
		onPress,
		isDisabled,
		isFullWidth,
		isTertiary,
		isSecondary,
		isOutlined,
		isContained,
		classes,
		isLoading,
	} = props;
	// primary (default) secondary icon outlined contained
	let buttonStyle = isTertiary ? style.tertiary : isSecondary ? style.secondary : style.primary;
	if (isOutlined) {
		buttonStyle = [buttonStyle, style.outlined].join(' ');
	}
	let containedStyle = isTertiary ? style.tertiaryContained : isSecondary ? style.secondaryContained : style.primaryContained;
	if (isContained) {
		buttonStyle = [buttonStyle, containedStyle].join(' ');
	}

	return (
		<button
			id={id}
			className={[buttonStyle, classes].join(' ')}
			style={{ width: isFullWidth ? '100%' : 'auto' }}
			onClick={(e) => onPress(e)}
			disabled={isLoading ? true : isDisabled}
		>
			{isLoading && <Spinner />}
			{icon ? <img className={style.icon} src={icon} alt={label + ' Button Icon'} /> : null}
			{isLoading ? 'Please Wait...' : label}
		</button>
	);
};

export default Button;
