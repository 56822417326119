import React from 'react'
import {scrollToPosition} from '../../../functions/functions'
import style from './ScrollDown.module.css'
import chevron from '../../../assets/icons/chevron-down.svg'

const ScrollDown = () => (
  <div
    className={style.container}
    onClick={() => scrollToPosition(window.innerHeight + window.scrollY)}
  >
    <img src={chevron} title='Scroll down' alt='Scroll down' />
  </div>
)

export default ScrollDown
